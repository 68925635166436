<template>
  <div class="one-question">
    <div class="title" @click="details(onequestion.id)">
      {{ onequestion.questionTitle }}
    </div>
    <div
      class="content"
      v-if="
        onequestion.questionContent == undefined ||
          onequestion.questionContent.length <= 100
      "
    >
      {{ onequestion.questionContent }}
    </div>
    <div
      class="content"
      v-if="
        onequestion.questionContent == undefined ||
          onequestion.questionContent.length > 100
      "
    >
      {{
        onequestion.questionContent == undefined
          ? ""
          : onequestion.questionContent.substring(0, 100) + "..."
      }}
    </div>
    <div class="keyword-list">
      <i class="keyword-icon"></i>
      <span
        @click="keyWordSearch(word)"
        class="keyword color-8590A6 title12"
        v-for="(word, index) in JSON.parse(onequestion.keyword)"
        :key="index"
      >
        {{ word }}
      </span>
    </div>
    <div class="other">
      <div class="clo-info">
        <i class="scan-icon"></i>
        <span class="color-8590A6">{{ onequestion.scanNum }}浏览</span>
      </div>
      <div class="clo-info">
        <i class="collect-icon"></i>
        <span class="color-8590A6">{{ onequestion.collectNum }}收藏</span>
      </div>
      <div class="clo-info-right">
        <span class="color-8590A6">{{ onequestion.answerNum }}回答</span>
        <span class="color-8590A6">{{ onequestion.createTime }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  QUERY,
  QUERYED,
  UPDATE,
  DELETE,
  INSERT,
  UPDATES,
} from "@/services/dao.js";
export default {
  // data(){
  //     return{
  //         onequestion:{
  //             answerNum:0,
  //             commentNum:200,
  //             collectNum:500,
  //             praiseNum:50,
  //             title:"",
  //             content:"",
  //             keyword:JSON.stringify( ["关键词1","关键词2","关键词3"])
  //         }
  //     }
  // },
  props: {
    onequestion: {
      type: Object,
      required: true,
    },
  },
  methods: {
    keyWordSearch(word) {
      ////标签搜索
      this.$router.push({
        path: "/automatic",
        query: {
          word: word,
        },
      });
    },
    async details(id) {
      // this.$emit('browse');
      console.log("id:" + id);
      let info = await QUERYED(
        "post",
        "",
        "  WDNewQuestion(where: {id: {_eq: " + id + "}}) { scanNum  }"
      );
      console.log("热度值" + info.data.WDNewQuestion[0].scanNum);
      let anser = Number(info.data.WDNewQuestion[0].scanNum);
      anser += 1;
      console.log("热度值2:" + anser);
      let dataed = await UPDATES(
        "post",
        "",
        "   update_WdQuestion(where: {id: {_eq: " +
          id +
          "}}, _set: {scanNum: " +
          anser +
          "}) { affected_rows  }"
      );
      if (dataed.data.update_WdQuestion.affected_rows > 0) {
        this.$router.push({
          path: "/writeanswer",
          query: {
            id: id,
          },
        });
      }
    },
  },
};
</script>

<style>
.one-question {
  width: 100%;
  display: inline-block;
}
.one-question > div {
  margin-top: 10px;
}
.one-question .title {
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.title:hover {
  color: coral;
}
.title12 {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.title12:hover {
  cursor: pointer;
  background-color: #fd6e05;
  color: white;
  border:0;
  border-color: white;
}
.one-question .content {
  /* border: 1px solid red; */
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
}
.one-question .keyword {
  border: 1px solid rgba(181, 181, 181, 1);
  border-radius: 13px;

  font-size: 12px;
  padding: 3px;
}
.one-question .keyword:not(:first-of-type) {
  margin-left: 5px;
}
.one-question .clo-info {
  height: 100%;
  width: 100px;
  display: inline-block;
  vertical-align: middle;
}

.one-question .clo-info-right {
  float: right;
}
.one-question .clo-info-right span {
  margin-left: 20px;
}
.color-8590A6 {
  color: #8590a6;
}
</style>
