<template>
  <div class="container">
    <div class="automatic">
      <span class="keysword">{{ word }}</span>
      <!-- <span class="height">话题共收集问题118个，悬赏问题118个，回答数123个</span> -->
    </div>
    <div class="content">
      <div class="counts-info">
        <span class="HT">话题自动组织 ></span>
        <span class="worded1">{{ word }}</span>
        <span class="SSJG">
          共
          <span style="color:red;font-weight: bold;margin-left:3px;margin-right:3px">{{nums}}</span>搜索结果
        </span>
      </div>

      <div class="list">
        <div class="item" v-for="oneQuestion in questionList" :key="oneQuestion.id">
          <V-OneQuestion :onequestion="oneQuestion"></V-OneQuestion>
        </div>
      </div>
      <!-- 分页 -->
      <div class="page-nav">
        <el-pagination
          background
          layout="prev, pager, next"
          :hide-on-single-page="hidePageNav"
          :total="totlePage"
          :page-size="pageSize"
          @prev-click="prevPage"
          @next-click="nextPage"
          @current-change="currentPage"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { DELETE, QUERY, QUERYED } from "@/services/dao.js";
import { formatDate, showTimeFormat } from "@/js/common";
import OneQuestion from "@/views/wisdom-answer/common/one-question/index.vue";
export default {
  data() {
    return {
      word: "",
      pageNum: 0,
      pageSize: 10,
      totlePage: 0,
      questionList: [],
      hidePageNav: true,
      nums: 0
    };
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化

      if (to.query.word != from.query.word) {
        this.word = this.$route.query.word;
        this.searchInfo(this.pageSize, 0); //重新加载数据
      }
    }
  },
  created() {
    this.word = this.$route.query.word;
    this.searchInfo(this.pageSize, 0);
  },
  components: {
    "V-OneQuestion": OneQuestion
  },
  methods: {
    async searchInfo(limit, offest) {
      let info = await QUERYED(
        "post",
        "",
        'WDQuestion( where: {keyword: {_like: "%' +
          this.word +
          '%"}},limit: ' +
          limit +
          ", offset: " +
          offest +
          ")   {     id     createTime     collectNum     answerNum     keyword     questionTitle     questionContent     scanNum   } "
      );
      this.questionList.splice(0, this.questionList.length);
      info.data.WDQuestion.forEach(element => {
        this.questionList.push({
          id: element.id,
          questionTitle: element.questionTitle,
          questionContent: element.questionContent,
          keyword: element.keyword,
          collectNum: element.collectNum,
          scanNum: element.scanNum,
          answerNum: element.answerNum,
          createTime: formatDate(Date.parse(element.createTime))
        });
      });
      //alert(this.questionList.length)
      this.totlePage = this.questionList.length;
      this.nums = this.questionList.length;
    },

    //前一页
    prevPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    }
  }
};
</script>

<style scoped>
@import url(~@/styles/page/index.css);
.automatic {
  background-color: rgb(255, 255, 255);
  width: 100%;
  padding: 25px 15% 25px 15%;
  /* height: 70px;
  line-height: 70px; */
  margin-top: 40px;
  /* border-top: 2px solid darkgray;
  border-bottom: 2px solid darkgray; */
  box-shadow: 3px 2px 2px 3px darkgray;
  font-size: 18px;
}
.content {
  width: 70%;
  background-color: #ffffff;
  margin: 50px auto;
  padding: 30px 50px;
}
.HT {
  font-size: 14px;
  font-weight: 300;
  color: rgb(7, 7, 7);
  border-bottom: 1px solid rgba(160, 160, 160, 1);
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}
.height {
  height: 40px;
  line-height: 40px;
  display: inline-block;
  /* background-color: fuchsia; */
}
.keysword {
  display: inline-block;
  color: rgb(9, 131, 245);
  font-weight: 500;
  background-color: rgb(205, 231, 253);
  height: 40px;
  line-height: 40px;
  padding: 2px 15px 2px 15px;
  border-radius: 17px;
  /* 超过固定宽度省略号显示 */
  max-width:300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 30px;
  vertical-align: top;
}
.worded1 {
  border: 1px solid rgba(181, 181, 181, 1);
  /* background-color: rgba(181, 181, 181, 1); */
  color: rgb(95, 95, 95);
  border-radius: 13px;
  /* font-weight:bold ; */
  font-size: 12px;
  padding: 5px;
  /* padding-bottom:7px ; */

  /* padding-top:6px ;
  padding-bottom:6px ;
  padding-left:5px;
  padding-right:5px; */
}
.ansers {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 100;
  color: #8590a6;
  line-height: 30px;
  /* border: 1px solid red; */
}
.ansers > span {
  /* margin-right: 15%; */
  /* border: 1px dashed darkblue; */
  display: inline-block;
  color: #8590a6;
}

.counts-info {
  font-size: 14px;
  font-weight: 300;
  color: rgba(160, 160, 160, 1);
  border-bottom: 1px solid rgba(160, 160, 160, 1);
  padding-bottom: 10px;
  font-size: 14px;
}
.item {
  padding: 20px 0;
  border-bottom: 1px dashed rgba(160, 160, 160, 1);
  line-height: 25px;
}
.item > div {
  /* border: 1px solid red; */
  width: 100%;
  font-size: 13px;
}
.item .title {
  width: 100%;
  height: 20px;
  line-height: 20px;
  padding-bottom: 15px;
  font-size: 15px;
  cursor: pointer;
  color: #0084ff;
  font-weight: bold;
  font-family: MicrosoftYaHei;
}
.collect2-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../assets/icon/Collection.png");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  /* border: 1px solid red; */
  position: relative;
  /* top: 5px; */
  left: 5px;
  top: 2px;
  margin-right: 5px;
}
.page-nav {
  width: 100%;
  /* border: 1px solid red; */
}
.SSJG {
  font-size: 12px;
  float: right;
  text-align: right;
}
</style>
